import { useFusionAuth } from '@fusionauth/react-sdk';
import React from 'react';
import Button from 'react-bootstrap/Button';

function Login() {
  const { login } = useFusionAuth();

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
      <Button className="primaryButton" onClick={() => login()}>
        Login
      </Button>
    </div>
  );
}

export default Login;
