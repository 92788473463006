import { FusionAuthProvider } from '@fusionauth/react-sdk';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './app';
import reportWebVitals from './reportWebVitals';
import './ui/app.scss';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <FusionAuthProvider
        clientID={process.env.REACT_APP_CLIENT_ID}
        redirectUri={process.env.REACT_APP_REDIRECT_URI}
        serverUrl={process.env.REACT_APP_AUTH_SERVER_URI}
        responseType="code"
      >
        <App />
      </FusionAuthProvider>
    </BrowserRouter>
  </React.StrictMode>,
);
reportWebVitals();
