import React from 'react';
import { Navbar, Nav, Button } from 'react-bootstrap';

function NavBarCompnent({ username, onLogout }) {
  return (
    <Navbar bg="none" expand="lg">
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav>
          <Navbar.Text>{username}</Navbar.Text>
          <Button style={{ marginLeft: 15 }} variant="outline-primary" onClick={onLogout}>
            Logout
          </Button>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default NavBarCompnent;
