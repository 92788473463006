import { useFusionAuth } from '@fusionauth/react-sdk';
import React, { useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import { Search } from 'tabler-icons-react';

const defaultObjectScanResult = {
  amount: 0,
  objects: [],
};

const fetchOptions = {
  timeout: 10000,
  credentials: 'include',
};

function LoadingOverlay({ visible }) {
  return visible ? <div className="loadingOverlay">loading...</div> : null;
}
function Scan() {
  const [input, setInput] = useState('');
  const [metamodel, setMetamodel] = useState([]);
  const [constants, setConstants] = useState([]);
  const [isSearchInProgress, setIsSearchInProgress] = useState(false);
  const [isSearchExecuted, setIsSearchExecuted] = useState(false);

  const { isAuthenticated, isLoading } = useFusionAuth();

  if (!isAuthenticated || isLoading) {
    return null;
  }

  const sortStrings = (a, b) => {
    const fa = a.toLowerCase();
    const fb = b.toLowerCase();

    if (fa < fb) {
      return -1;
    }
    if (fa > fb) {
      return 1;
    }
    return 0;
  };

  const objectsArePresent = (element) => {
    return (
      element.objectScanResult &&
      element.objectScanResult.objects &&
      element.objectScanResult.objects.length > 0
    );
  };

  const getConstants = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URI}/api/constants?url=${input}`,
        fetchOptions,
      );
      const constantsResult = await response.json();
      return constantsResult;
    } catch (e) {
      console.error('getConstants error: ', e);
      return [];
    }
  };

  const getObjects = async (name) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URI}/api/objects?url=${input}&entityName=${name}`,
        fetchOptions,
      );
      const result = await response.json();
      return result;
    } catch (e) {
      console.error('getObject error: ', e);
      return defaultObjectScanResult;
    }
  };

  const getMetaModel = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URI}/api/metamodel?url=${input}`,
        fetchOptions,
      );
      const metamodelResult = await response.json();

      metamodelResult.sort((a, b) => {
        return sortStrings(a.name, b.name);
      });

      for (let i = 0; i < metamodelResult.length; i += 1) {
        metamodelResult[i].attributes.sort((a, b) => {
          return sortStrings(a, b);
        });
      }
      return metamodelResult;
    } catch (e) {
      console.error('getMetaModel error: ', e);
      return [];
    }
  };

  const onClickGetMetamodel = async () => {
    setIsSearchExecuted(false);
    setMetamodel([]);
    setConstants([]);
    setIsSearchInProgress(true);
    try {
      const result = await Promise.all([getMetaModel(), getConstants()]);
      setMetamodel(result[0]);
      setConstants(result[1]);
      setIsSearchExecuted(true);
      const metaModelData = result[0];
      if (metaModelData.length > 0) {
        metaModelData.forEach(async (element, i) => {
          const objectResult = await getObjects(element.name);
          element.objectScanResult = objectResult;
          setMetamodel([...metaModelData]);
          if (i === metaModelData.length - 1) {
            setIsSearchInProgress(false);
          }
        });
      } else {
        setIsSearchInProgress(false);
      }
    } catch (error) {
      console.error('Error:', error);
      setIsSearchInProgress(false);
    }
  };

  const renderConstantElements = () => {
    return constants.map((constant) => {
      return (
        <div
          key={`${constant.name}-${constant.value}`}
          style={{ display: 'flex', marginBottom: 5 }}
        >
          <div style={{ fontWeight: 'bold' }}>{constant.name}</div>
          <div>{`: ${constant.value}`}</div>
        </div>
      );
    });
  };

  const getMetaModelItemTitle = (element) => {
    if (objectsArePresent(element)) {
      return `${element.name} (${element.objectScanResult.amount})`;
    }
    return element.name;
  };

  const getMetaModelAccordionClass = (element) => {
    if (objectsArePresent(element)) {
      return 'accordion-button-danger';
    }
    return '';
  };

  const renderObjectElements = (metaDataElement) => {
    return metaDataElement.objectScanResult.objects.flatMap((element) => {
      return (
        <div className="objectDiv" key={element.id}>
          {Object.keys(element).map((key, j) => {
            return (
              <div key={`${key}-${element.id}`}>
                <div>{`${key}: ${JSON.stringify(element[Object.keys(element)[j]])}`}</div>
              </div>
            );
          })}
        </div>
      );
    });
  };

  const renderMetaModelElements = () => {
    return metamodel.map((element) => {
      return (
        <Accordion.Item
          eventKey={element.name}
          key={element.name}
          className={getMetaModelAccordionClass(element)}
        >
          <Accordion.Header>{getMetaModelItemTitle(element)}</Accordion.Header>
          <Accordion.Body>
            <h5>Attributes</h5>
            <div className="metamodelElementWithobjectScanResult">
              <div className="metamodelElement">
                <div>
                  {element.attributes.map((attribute) => {
                    return (
                      <div key={`${element.name}-${attribute}`} className="metamodelAttribute">
                        {attribute}
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="objectsMainDiv">
                {objectsArePresent(element) && (
                  <>
                    <div style={{ display: 'flex' }}>
                      <h6>{`Found objects (${element.objectScanResult.amount})`}</h6>
                      {element.objectScanResult.amount > 10 && (
                        <span style={{ fontSize: 'small', marginLeft: 15 }}> (max 10 shown)</span>
                      )}
                    </div>
                    {renderObjectElements(element)}
                  </>
                )}
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      );
    });
  };

  return (
    <>
      <LoadingOverlay visible={isSearchInProgress} />
      <div className="metamodelSearchDiv">
        <Form.Control
          type="text"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          style={{ maxWidth: 400, width: '80%' }}
          icon={<Search />}
          placeholder="Mendix url"
        />
        {isSearchInProgress && <Spinner animation="border" />}
        {!isSearchInProgress && (
          <Button
            className="primaryButton"
            onClick={() => onClickGetMetamodel()}
            disabled={input.length <= 5 || isSearchInProgress}
          >
            Retrieve Metamodel
          </Button>
        )}
      </div>
      {isSearchExecuted && (
        <div className="metaDataAndConstantsMainDiv" style={{ marginTop: 50 }}>
          <div key="metaDataAndConstantsDiv1" className="metaDataAndConstantsDiv">
            <h2 key="metaDataAndConstantsDiv2-h1" style={{ marginBottom: 20 }}>
              Metamodel
            </h2>
            {metamodel.length > 0 ? (
              <Accordion className="metamodelElements">{renderMetaModelElements()}</Accordion>
            ) : (
              'No metamodel found'
            )}
          </div>
          <div key="metaDataAndConstantsDiv2" className="metaDataAndConstantsDiv">
            <h2 key="metaDataAndConstantsDiv2-h2" style={{ marginBottom: 20 }}>
              Constants
            </h2>
            {constants.length > 0 ? (
              <div>{renderConstantElements()}</div>
            ) : (
              'No exposed constants found'
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default Scan;
