import { RequireAuth, useFusionAuth } from '@fusionauth/react-sdk';
import React, { useEffect } from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import logo from './assets/jamit.png';
import NavBarCompnent from './components/navbar';
import Login from './pages/login/login';
import Scan from './pages/scan/scan';

function App() {
  const navigate = useNavigate();
  const { isAuthenticated, logout, user } = useFusionAuth();

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/scan');
    }
  }, [isAuthenticated, navigate]);

  const handleLogout = async () => {
    await logout();
    navigate('/');
  };

  return (
    <div className="app">
      <RequireAuth>
        <NavBarCompnent username={user.email} onLogout={() => handleLogout()} />
      </RequireAuth>
      <a href="https://jam-it.nl" target="_blank" rel="noopener noreferrer">
        <img src={logo} alt="logo" style={{ width: 300, marginTop: 25 }} />
      </a>
      <h1 order={1} style={{ marginTop: 50, marginBottom: 50 }}>
        Mendix security scan
      </h1>
      <div style={{ flex: 1 }}>
        <Routes>
          <Route path="/" element={<Login />} />
          {isAuthenticated && <Route path="/scan" element={<Scan />} />}
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </div>
    </div>
  );
}

export default App;
